import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import { Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

function Registration({ registrationDetailsData, setRegistrationDetailsData }) {
  const addData = () => {
    setRegistrationDetailsData([
      ...registrationDetailsData,
      {
        groupHeading: "Registration Packages: Choose Your Pass",
        description: "",
        position: 0,
        publish: false,
        data: [],
      },
    ]);
    toast.success("New Registration group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (registrationDetailsData.length > 0) {
      const updatedData = [...registrationDetailsData];
      updatedData.pop();
      setRegistrationDetailsData(updatedData);
      toast.success("Last Registration group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No Registration groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this Registration section?")) {
      setRegistrationDetailsData(registrationDetailsData.filter((_, i) => i !== index));
      toast.success("Registration group deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAddRegistration = (groupIndex) => {
    const updatedData = [...registrationDetailsData];
    updatedData[groupIndex].data.push({
      registrationType: "",
      registrationLink: "",
      registrationFee: 0,
      comments: "",
      description: "",
      saveMySeat: "",
    });
    setRegistrationDetailsData(updatedData);
  };

  const handleRemoveRegistration = (groupIndex) => {
    const updatedData = [...registrationDetailsData];
    if (updatedData[groupIndex].data.length > 0) {
      updatedData[groupIndex].data.pop();
      setRegistrationDetailsData(updatedData);
    }
  };

  const updateRegistrationData = (groupIndex, dataIndex, key, value) => {
    const updatedData = [...registrationDetailsData];
    updatedData[groupIndex].data[dataIndex] = {
      ...updatedData[groupIndex].data[dataIndex],
      [key]: value,
    };
    setRegistrationDetailsData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[250px] flex justify-around">
            Registration
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {registrationDetailsData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {registrationDetailsData.map((registrationGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className='mt-1 border-2 border-solid border-[#489FC5] rounded-md'
        >
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px"
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Group Heading
              </label>
              <RichTextEditor
                name={"groupHeading"}
                value={registrationGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...registrationDetailsData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  setRegistrationDetailsData(updatedData);
                }}
              />
            </div>

            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Description
              </label>
              <RichTextEditor
                name={"description"}
                value={registrationGroup.description}
                onChange={(e) => {
                  const updatedData = [...registrationDetailsData];
                  updatedData[groupIndex].description = e.target.value;
                  setRegistrationDetailsData(updatedData);
                }}
              />
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={registrationGroup.position}
                  onChange={(e) => {
                    const updatedData = [...registrationDetailsData];
                    updatedData[groupIndex].position = e.target.value;
                    setRegistrationDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
                />
              </div>
              <div className=" flex-1 ">
                <div className=" ">
                  <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
                    Publish :
                    <select
                      value={registrationGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...registrationDetailsData];
                        updatedData[groupIndex].publish = e.target.value === "true";
                        setRegistrationDetailsData(updatedData);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <div className="mx-auto my-10 w-11/12">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Registration Data
                <AddCircleIcon
                  onClick={() => handleAddRegistration(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemoveRegistration(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />
              </Typography>
              {registrationGroup.data.map((registrationItem, dataIndex) => (
                <div key={dataIndex}>
                  <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                  <div className="flex w-full gap-2  my-6 mb-10 mx-auto ">
                    <div className="flex-1">
                      <label className=" mb-6 text-xl  font-medium text-[#489FC5]">
                        Registration Type
                      </label>
                      <RichTextEditor
                        value={registrationItem.registrationType}
                        onChange={(e) =>
                          updateRegistrationData(
                            groupIndex,
                            dataIndex,
                            "registrationType",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className=" flex-1">
                      <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                        Comments
                      </label>
                      <RichTextEditor
                        type="text"
                        value={registrationItem.comments}
                        onChange={(e) =>
                          updateRegistrationData(
                            groupIndex,
                            dataIndex,
                            "comments",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="flex w-full gap-2 my-6 mb-10 mx-auto ">

                    <div className=" flex-1">
                      <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                        Description
                      </label>
                      <RichTextEditor
                        type="text"
                        value={registrationItem.description}
                        onChange={(e) =>
                          updateRegistrationData(
                            groupIndex,
                            dataIndex,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className=" flex-1">
                      <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                        Save My Seat
                      </label>
                      <RichTextEditor
                        type="text"
                        value={registrationItem.saveMySeat}
                        onChange={(e) =>
                          updateRegistrationData(
                            groupIndex,
                            dataIndex,
                            "saveMySeat",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="flex w-full gap-2 my-6 mb-10 mx-auto ">
                    <div className="flex-1">
                      <label className=" mb-6 text-xl font-medium text-[#489FC5]">
                        Registration Fee
                      </label>
                      <input
                        type="number"
                        value={registrationItem.registrationFee}
                        onChange={(e) =>
                          updateRegistrationData(
                            groupIndex,
                            dataIndex,
                            "registrationFee",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-[96%]"
                      />

                    </div>
                    <div className="flex-1">
                      <div className="flex-1">
                        <label className="mb-6 text-xl font-medium text-[#489FC5]">
                          Registration Link
                        </label>
                        <input
                          value={registrationItem.registrationLink}
                          type="url"
                          onChange={(e) =>
                            updateRegistrationData(
                              groupIndex,
                              dataIndex,
                              "registrationLink",
                              e.target.value
                            )
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-[96%]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
            </div>
          </form>
        </div>
      ))}
    </>
  );
}

export default Registration;

